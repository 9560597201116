export default {
    computed: {
        classLangBtn() {
            return {
                "mr-6": this.$vuetify.rtl,
                "ml-6": !this.$vuetify.rtl,
            };
        },
        classLang() {
            return {
                "text-left": this.$vuetify.rtl,
                "text-right": !this.$vuetify.rtl,
            };
        },
    }
}