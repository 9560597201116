<template>
  <div class="printInvoice">
    <Loading v-if="loading"></Loading>
    <v-btn
      :ripple="false"
      :class="classLangBtn"
      class="text-white print-btn bg-gradient-default font-weight-bolder"
      @click="print()"
    >
      {{ $t("print") }}
    </v-btn>
    <div class="contruct-print">
      <v-container class="py-8" fluid>
        <v-row class="">
          <v-col cols="4" class="d-flex justify-center">
            <div
              v-if="setting"
              class="d-flex flex-column align-center mt-4 div-head"
            >
              <div class="text-center">
                <h4 class="mt-3 text-xs font-weight-bolder title-header">
                  Altal
                </h4>
              </div>
              <div class="text-center">
                <h4 class="mt-3 text-center text-xs title-header">
                  {{ setting.location }}
                </h4>
                <h4 class="mt-3 text-center text-xs title-header">
                  Tel {{ setting.phone }}
                </h4>
              </div>
            </div>
          </v-col>
          <v-col cols="4" class="text-center">
            <img
              width="220"
              src="@/assets/img/current/logo.png"
              class="navbar-brand-img mx-auto kidana"
            />
          </v-col>
          <v-col cols="4" class="d-flex justify-center">
            <div
              v-if="contractor"
              class="d-flex flex-column align-center mt-4 div-head"
            >
              <div class="text-center">
                <h4 class="mt-3 title-header text-xs font-weight-bolder">
                  {{ contractor.company_name }}
                </h4>
              </div>
              <div class="text-center">
                <h4 class="mt-2 text-center title-header text-xs">
                  {{ contractor.address }}
                </h4>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col v-if="request" cols="12" class="offset-1">
            <div class="d-flex">
              <h4 class="text-xs">{{ $t("request.invoice_num") }} :</h4>
              <h4 class="text-xs mx-10">{{ request.id }}</h4>
            </div>

            <div class="d-flex">
              <h4 class="text-xs">{{ $t("request.invoice_date") }} :</h4>
              <h4 class="text-xs mx-10">{{ request.request_date }}</h4>
            </div>

            <div class="d-flex">
              <h4 class="text-xs">{{ $t("request.expire_date") }} :</h4>
              <h4 class="text-xs mx-10">{{ request.expire_date }}</h4>
            </div>
          </v-col>
          <v-col cols="12" v-if="items.length > 0">
            <div class="table-wrapper">
              <table class="main-table">
                <thead>
                  <tr>
                    <!-- <th class="py-3 outer-th text-sm empty-th"></th> -->
                    <th class="py-3 outer-th text-sm">
                      {{ $t("request.description") }}
                    </th>
                    <th class="py-3 outer-th text-sm">
                      {{ $t("daily.number_week") }}
                    </th>
                    <th class="py-3 outer-th text-sm">
                      {{ $t("Locatie") }}
                    </th>
                    <th class="py-3 outer-th text-sm">
                      {{ $t("request.employee_total") }}
                    </th>
                    <th class="py-3 outer-th text-sm">
                      {{ $t("request.start_time") }}
                    </th>
                    <th class="py-3 outer-th text-sm">
                      {{ $t("request.end_time") }}
                    </th>
                    <th class="py-3 outer-th text-sm">
                      {{ $t("daily.count_hour") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <!-- <td class="text-xs text-typo text-center ls-0"></td> -->
                    <td class="text-xs text-typo text-center ls-0">
                      {{ item.note ? item.note : "-" }}
                    </td>
                    <td class="text-xs text-typo text-center ls-0">
                      {{ item.number_week }}
                    </td>
                    <td class="text-xs text-typo text-center ls-0">-</td>
                    <td class="text-xs text-typo text-center ls-0">
                      {{ item.count_hour }}
                    </td>
                    <td class="text-xs text-typo text-center ls-0">
                      {{ item.start_time }}
                    </td>
                    <td class="text-xs text-typo text-center ls-0">
                      {{ item.end_time }}
                    </td>
                    <td class="text-xs text-typo text-center ls-0">
                      {{ item.count_hour }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
        <div cols="12" v-if="request" class="mt-10 mx-2">
          <v-row class="border-bottom-gray">
            <v-col md="9" cols="7"></v-col>
            <v-col
              cols="3"
              md="2"
              class="text-xs bg-footer font-weight-bold text-end"
              >{{ $t("daily.total_count_hour") }}</v-col
            >
            <v-col cols="2" md="1" class="text-xs bg-footer font-weight-bold">{{
              request.count_hour
            }}</v-col>
          </v-row>
          <v-row>
            <v-col md="9" cols="6"></v-col>
            <v-col cols="4" md="2" class="text-end text-xs">{{
              $t("request.total_excl_btw")
            }}</v-col>
            <v-col cols="2" md="1" class="text-xs">{{ request.price }}</v-col>
          </v-row>
          <v-row class="border-bottom-gray mt-5">
            <v-col md="9" cols="7"></v-col>
            <v-col cols="3" md="2" class="text-end text-xs">{{
              $t("request.tax") + " " + tax + "%"
            }}</v-col>
            <v-col cols="2" md="1" class="text-xs">{{
              (parseFloat(request.price) * tax) / 100
            }}</v-col>
          </v-row>
          <v-row>
            <v-col md="9" cols="6"></v-col>
            <v-col
              cols="4"
              md="2"
              class="bg-footer text-end font-weight-bold text-xs"
              >{{ $t("request.total_incl_btw") }}</v-col
            >
            <v-col cols="2" md="1" class="text-xs bg-footer font-weight-bold">{{
              request.total_price
            }}</v-col>
          </v-row>
        </div>
        <div
          v-if="line_request"
          class="text-center text-sm mt-10"
          style="text-decoration: underline"
        >
          {{ line_request }}
        </div>

        <div
          v-if="contractor"
          class="text-center text-md mt-5 font-weight-bolder"
        >
          <span v-if="contractor.btw">
            {{ $t("request.tax") + ": " + contractor.btw + " * " }}
          </span>
          <span v-if="contractor.kvk">
            {{ "KVK: " + contractor.kvk + " * " }}
          </span>
          <span v-if="contractor.ibaw">
            {{ "IBAN: " + contractor.ibaw + " *  BIC: INGBNL2A" }}
          </span>
        </div>
      </v-container>
    </div>
  </div>
</template>
  <script>
import classLang from "../../../mixin/classLange";
import Loading from "../Components/Loading";
import requestService from "../../../services/request.service";
export default {
  mixins: [classLang],
  components: {
    Loading,
  },
  data() {
    return {
      items: [],
      setting: null,
      contractor: null,
      request: null,
      loading: false,
      tax: 0,
      line_request: "",
    };
  },
  methods: {
    print() {
      window.print();
    },
  },
  mounted() {
    document.title = this.$i18n.t("InvoiceRequests");
    this.loading = true;
    return requestService.details(this.$route.params.id).then(
      (response) => {
        this.loading = false;
        this.request = response.data.request;
        this.items = response.data.data;
        this.setting = response.data.setting;
        this.contractor = response.data.contractor;
        this.tax = response.data.setting.tax;
        this.line_request = response.data.setting.first_line_request;
        // var self = this;
      },
      (error) => {
        this.loading = false;
        // console.log(error);
      }
    );
  },
};
</script>
<style scoped>
/* table  */
table {
  width: 100%;
  /*border-spacing: 10px 5px;*/
  /*border-collapse: collapse;*/
}

.main-table {
  border-spacing: 0px 10px;
}

.inner-table {
  border-collapse: collapse;
}

th {
  padding: 10px;

  text-align: center;
  /* border: 1px solid #e9ecef; */
  background-color: #eceded;
}
td {
  padding: 10px;
}
.inner-table-row > td {
  border: 1px solid #e9ecef;
}

.outer-th {
  /* width: 20%; */
}
.border-bottom-gray {
  border-bottom: 1px solid #777;
}
.bg-footer {
  padding: 5px;
  background: linear-gradient(180deg, #fff, #dee2e6);
}
.print-btn {
  position: absolute;
  top: 20px;
  left: 47px;
}
</style>